<template>
  <div id="LawPlay">
    <Map :config="bigMapConfig"
         :lawFlyingParams="lawFlyingParams" />
    <div class="infoBox"
         @scroll="scrollEvent">
      <!--用于设置跳转锚点-->
      <div id="topAnchor"></div>
      <!--返回-->
      <div class="getBack"
           @click="$router.go(-1)">
        <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
        <span>返回</span>
      </div>
      <div class="content">
        <div class="top">
          <div class="photoTabs"
               :style="
              checked == 'photo'
                ? 'borderBottom:3px solid #409EFF'
                : 'borderBottom:3px solid #f8f8f8'
            "
               @click="checked = 'photo'">
            照片
          </div>
          <div :style="
              checked == 'video'
                ? 'borderBottom:3px solid #409EFF'
                : 'borderBottom:3px solid #f8f8f8'
            "
               @click="checked = 'video'">
            视频
          </div>
        </div>
        <div class="info">
          <!-- 照片 -->
          <div class="photoBox"
               v-show="checked == 'photo'">
            <h1 v-if="!photoData.length">暂无照片</h1>
            <div class="bigImgWrap">
              <viewer :images="photoData">
                <div v-for="el in photoData"
                     :key="el.law_id">
                  <img :src="el.path" />
                </div>
              </viewer>
            </div>

            <!-- <div
              v-for="(photoItem, photoInd) in photoData"
              @click="lookPhoto(photoData, photoInd)"
            >
              <img :src="photoItem.path" alt="" />
            </div> -->
          </div>
          <!-- 视频 -->
          <div class="videoBox"
               v-show="checked == 'video'">
            <h1 v-if="!videoData.length">暂无视频</h1>
            <div class="bigImgWrap">
              <video v-for="el in videoData"
                     :key="el.law_id"
                     :src="el.path"
                     controls></video>
            </div>
          </div>
        </div>
      </div>
      <div class="goTop"
           @click="scrollToSection"
           ref="goTop">回到顶部</div>
    </div>
    <BigImgModel v-if="imgModal"
                 :imgList="bigImgList"
                 :imgTime="bigImgTime"
                 :idx="bigIndex"
                 @closeBigImgModel="closeImgModal()"></BigImgModel>
    <!-- 视频弹窗 -->
    <!-- <Modal v-model="vedioModel"
           footer-hide
           width="800"
           title="视频"
           @on-ok="vedioModelOk"
           @on-cancel="vedioModelCancel">
      <div class="vedio">
        <video controls
               v-if="bigVideoSrc"
               ref="video"
               :src="bigVideoSrc"
               autoplay>
          您的浏览器不支持Video标签
        </video>
      </div>
    </Modal> -->
  </div>
</template>

<script>
import Map from '@/components/statistics/commonComponents/map.vue';
import BigImgModel from '@/components/statistics/commonComponents/bigImgModel.vue';
import { lawDetail } from '@/utils/javaApi.js';

export default {
  components: { Map, BigImgModel },
  data() {
    return {
      bigMapConfig: {
        tools: ['hierarchy', 'zoomIn', 'zoomOut'],
      },
      lawFlyingParams: '',
      data: '',
      checked: 'photo',
      photoData: [],
      videoData: [],
      bigIndex: 0,
      bigImgList: [],
      bigImgTime: '',
      imgModal: false,
      bigVideoSrc: '',
      vedioModel: false,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    checked(val) {
      if (val == 'photo') {
      } else if (val == 'video') {
      }
    },
  },
  methods: {
    // 请求数据
    getData() {
      let track_id = this.$route.query.track_id;
      this.photoData = [];
      this.videoData = [];
      this.$post(lawDetail(), {
        client: 'web',
        law_id: track_id,
      })
        .then((res) => {
          if (res.data) {
            this.lawFlyingParams = res.data;
            console.log(this.lawFlyingParams);
            res.data.media.forEach((item, ind) => {
              if (item.media_type == 1) {
                this.photoData.push(item);
              } else if (item.media_type == 2) {
                this.videoData.push(item);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '获取执法仪轨迹数据失败,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
    // 监听滚动距离
    scrollEvent() {
      var top = this.$refs.ProjectBar.scrollTop;
      if (top >= 450) {
        this.$refs.goTop.style.display = 'block';
      } else {
        this.$refs.goTop.style.display = 'none';
      }
    },
    // 回到顶部
    scrollToSection() {
      document
        .getElementById('topAnchor')
        .scrollIntoView({ behavior: 'smooth' });
    },

    //视频双击全屏
    videoFullscreen(name) {
      var ele = this.$refs[name][0];
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
      }
    },
    //查看图片
    lookPhoto(m, idx) {
      this.bigIndex = idx;
      // let n = [];
      // m.forEach((e) => {
      //   n.push({ path: e });
      // });
      //   console.log(n);
      this.bigImgList = m;
      this.imgModal = true;
    },
    // 关闭弹窗
    closeImgModal() {
      /**
       * 照片
       * bigIndex: 选中图片的索引值
       * bigImgList: 选中图片所在的图片数组
       * bigImgTime: 选中图片的时间，选填，也可用于标题展示
       * imgModal: 插件显示
       */
      document.documentElement.style.overflowY = 'auto';
      this.imgModal = false;
      // this.imgModalData.time = "";
      // this.imgModalData.lists = [];
      this.bigIndex = 0;
      this.bigImgList = [];
      this.bigImgTime = '';
    },
  },
};
</script>

<style scoped lang="scss">
.bigImgWrap {
  padding-top: 20px;
  & > div {
    cursor: pointer;
    padding: 0 10px;
    height: 250px;
    & > div {
      height: 250px;
    }
  }

  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    padding-bottom: 20px;
  }

  video {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    padding-bottom: 20px;
  }
}
.vedio {
  display: flex;
  justify-content: center;
  video {
    max-width: 100%;
    max-height: 70vh;
  }
}
#LawPlay {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #adadad;
  .infoBox {
    width: 340px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    .getBack {
      background-color: #fff;
      width: 100%;
      height: 40px;
      padding-left: 20px;
      cursor: pointer;
      margin-bottom: 20px;
      border-bottom: 1px solid #f3f3f3;
      i {
        font-size: 22px;
        color: #111;
        margin-right: 6px;
        line-height: 40px;
      }
      span {
        font-size: 18px;
        color: #111;
        line-height: 40px;
      }
    }
    .goTop {
      width: 80px;
      height: 30px;
      font-size: 12px;
      color: #fff;
      border-radius: 4px;
      background-color: #409eff;
      text-align: center;
      line-height: 30px;
      position: fixed;
      bottom: 20px;
      left: 80px;
      cursor: pointer;
      display: none;
    }
    .content {
      width: 100%;
      .top {
        width: 100%;
        height: 70px;
        display: flex;
        > div {
          width: 50%;
          height: 70px;
          border-bottom: 3px solid #f8f8f8;
          text-align: center;
          line-height: 70px;
          font-size: 20px;
          font-weight: 800;
          cursor: pointer;
        }
      }
      .info {
        width: 100%;
        > div {
          // width: 100%;
          // padding: 20px 10px;
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          h1 {
            font-size: 20px;
            text-align: center;
            color: #999;
          }
          // > div {
          //   width: 156px;
          //   height: 156px;
          //   background-color: #ececec;
          //   position: relative;
          //   cursor: pointer;
          //   > img {
          //     width: 100%;
          //     height: auto;
          //     position: absolute;
          //     top: 50%;
          //     transform: translateY(-50%);
          //   }
          //   > video {
          //     width: 100%;
          //     height: auto;
          //     position: absolute;
          //     top: 50%;
          //     transform: translateY(-50%);
          //   }
          // }
        }
      }
    }
  }
}
</style>
