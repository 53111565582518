<template>
  <div>
    <div class="aside">
      <p v-if="imgTime"
         class="img-modal-time">{{ imgTime }}</p>
      <div v-else
           class="img-modal-time"></div>
      <Icon type="md-close"
            color="white"
            class="close"
            size="30"
            @click="closeImgModal()" />
      <div v-show="bigImgUrl">
        <img :src="bigImgUrl"
             style="maxWidth:90%;height:700px;margin:0 auto" />
      </div>
      <!-- <div class="big-img-box"
           v-show="bigImgUrl"
           :style="{background: 'url(' + bigImgUrl + ')'}">{{bigImgUrl}}</div> -->
      <div class="arrow"
           @click="backImg()"
           style="left: 10px">
        <Icon type="ios-arrow-back"
              color="white"
              size="24" />
      </div>
      <div class="arrow"
           @click="forwardImg()"
           style="right: 10px">
        <Icon type="ios-arrow-forward"
              color="white"
              size="24" />
      </div>
      <div class="big-list-box">
        <ul>
          <li v-for="(l, index) in imgList"
              :key="index">
            <img :src="l"
                 @click="chooseImg(index)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bigImgModel',
  props: {
    imgList: {
      type: Array,
    },
    idx: {
      type: Number,
    },
    imgTime: {
      type: String,
    },
  },
  data() {
    return {
      bigImgUrl: '',
      bigIndex: 0,
    };
  },
  mounted() {
    document.documentElement.style.overflowY = 'hidden';
    this.bigImgUrl = this.imgList[this.idx];
    this.bigIndex = this.idx;
    setTimeout(() => {
      this.changeBigListBoxChoose(this.idx);
    }, 100);
  },
  methods: {
    chooseImg(idx) {
      this.bigIndex = idx;
      this.bigImgUrl = this.imgList[idx];
      this.changeBigListBoxChoose(idx);
    },
    forwardImg() {
      if (this.bigIndex < this.imgList.length - 1) {
        this.bigIndex++;
        this.bigImgUrl = this.imgList[this.bigIndex];
        this.changeBigListBoxChoose(this.bigIndex);
      }
    },
    backImg() {
      if (this.bigIndex > 0) {
        this.bigIndex--;
        this.bigImgUrl = this.imgList[this.bigIndex];
        this.changeBigListBoxChoose(this.bigIndex);
      }
    },
    // big-list-box的选中样式
    changeBigListBoxChoose(n) {
      let lis = document.querySelectorAll('.big-list-box>ul>li');
      lis.forEach((e) => {
        e.setAttribute('class', '');
      });
      lis[n].setAttribute('class', 'active');
    },
    // 关闭弹窗
    closeImgModal() {
      document.documentElement.style.overflowY = 'auto';
      this.$emit('closeBigImgModel');
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  .img-modal-time {
    width: 100%;
    color: #fff;
    font-size: 16px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .big-img-box {
    width: 90%;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .arrow {
    position: absolute;
    top: 51%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .big-list-box {
    width: 100%;
    height: 100px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        // width: 62px;
        height: 62px;
        // overflow: hidden;
        margin: 15px 5px 0;
        border: 4px solid #000;
        box-sizing: content-box;
        img {
          height: 62px;
          margin: 0 auto;
        }
      }
      .active {
        border: 4px solid #4c98ff;
      }
    }
  }
}
.big-list-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
</style>
